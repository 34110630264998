import { CompanyTypeEnum } from "@/lib/enum/company-type.enum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import {
  MrfiktivCompanyViewModelGen,
  MrfiktivCreateCompanyDtoGen,
  MrfiktivUpdateCompanyDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgContactDocumentGen } from "@/services/thg/v1/data-contracts";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { Address, IAddress } from "./address.entity";
import { Contact } from "./contact.entity";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { CompanyDataAccessLayer } from "@/store/modules/access-layers/company.access-layer";
import companyService from "@/services/mrfiktiv/services/companyService";
import { IsCustomViewable } from "@/lib/interfaces/is-custom-viewable.interface";
import Vue from "vue";
import { CompanyGroupModule } from "@/store/modules/company-group.store";

@IsFilterable
export class CompanyBase
  implements Omit<MrfiktivCompanyViewModelGen, "values">, ICreateDto<ICompany>, IsCustomViewable {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.id",
    config: {
      itemCallback: () => CompanyDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company"
    }
  })
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.groupId",
    config: {
      itemCallback: () => CompanyGroupModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company-group"
    }
  })
  groupId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    type: Address
  })
  address?: IAddress;

  @FilterConfig({
    type: Contact
  })
  contact?: ThgContactDocumentGen;

  @FilterConfig({
    displayName: "objects.company.type",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CompanyTypeEnum).map(v => {
        return {
          text: `enums.CompanyTypeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  type: CompanyTypeEnum;

  @FilterConfig({
    displayName: "objects.company.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees?: string[];

  company?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.companyName"
  })
  companyName: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.firstName"
  })
  firstName?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.lastName"
  })
  lastName?: string;

  isCompany?: boolean;

  @FilterConfig({
    displayName: "objects.company.language",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(LanguageCodeEnum).map(v => {
        return {
          text: `enums.LanguageCodeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  language: LanguageCodeEnum;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.taxnumber"
  })
  taxnumber?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.company.isTaxDeductible"
  })
  isTaxDeductible?: boolean;

  values: ICustomFieldValue[] = [];

  loading = false;

  get titleReadable() {
    return this.companyName;
  }

  /**
   * Construct company
   */
  constructor(company?: Partial<CompanyBase | MrfiktivCompanyViewModelGen>) {
    this.id = company?.id ?? "";
    this.userId = company?.userId;
    this.partnerId = company?.partnerId ?? "";
    this.timestamp = new Timestamp(company?.timestamp);
    this.address = company?.address ? new Address(company?.address) : undefined;
    this.contact = company?.contact ? new Contact(company?.contact) : undefined;
    this.type = (company?.type ?? CompanyTypeEnum.OTHER) as CompanyTypeEnum;
    this.assignees = company?.assignees || [];
    this.company = company?.companyName;
    this.companyName = company?.companyName ?? "";
    this.firstName = company?.firstName;
    this.lastName = company?.lastName;
    this.groupId = company?.groupId ?? "";
    this.isCompany = company?.isCompany;
    this.language = (company?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.taxnumber = company?.taxnumber;
    this.isTaxDeductible = company?.isTaxDeductible;
    this.values = CustomFieldValue.buildCustomFieldValues(company?.values || []);
  }

  /**
   * fetch company
   */
  async fetch(): Promise<this> {
    this.loading = true;

    try {
      const res = await companyService.getOne(this.partnerId, this.id);

      this.map(res);
      CompanyDataAccessLayer.set(this);
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
    } finally {
      this.loading = false;
    }

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  protected map(company?: MrfiktivCompanyViewModelGen) {
    if (!company) return;
    this.id = company?.id ?? "";
    this.userId = company?.userId;
    this.partnerId = company?.partnerId ?? "";
    this.timestamp = new Timestamp(company?.timestamp);
    this.address = company?.address ? new Address(company?.address) : undefined;
    this.contact = company?.contact ? new Contact(company?.contact) : undefined;
    this.type = (company?.type ?? CompanyTypeEnum.OTHER) as CompanyTypeEnum;
    this.assignees = company?.assignees || [];
    this.company = company?.companyName;
    this.companyName = company?.companyName ?? "";
    this.firstName = company?.firstName;
    this.lastName = company?.lastName;
    this.groupId = company?.groupId ?? "";
    this.isCompany = company?.isCompany ?? true;
    this.language = (company?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.taxnumber = company?.taxnumber;
    this.isTaxDeductible = company?.isTaxDeductible;
    this.values = CustomFieldValue.buildCustomFieldValues(company?.values || []);
  }

  /**
   * create fetch company
   */
  async create() {
    const data: MrfiktivCreateCompanyDtoGen = {
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      isCompany: this.isCompany,
      company: this.companyName,
      companyName: this.companyName,
      contact: this.contact || undefined,
      address: this.address || undefined,
      groupId: this.groupId,
      isTaxDeductible: this.isTaxDeductible,
      language: this.language || undefined,
      type: this.type || undefined,
      assignees: this.assignees || undefined,
      taxnumber: this.taxnumber || undefined,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || [])
    };
    const res = await companyService.create(this.partnerId, data);

    this.map(res);

    CompanyDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete company
   */
  async delete() {
    const res = await companyService.delete(this.partnerId, this.id);

    this.map(res);
    CompanyDataAccessLayer.delete(this);
  }

  /**
   * update company
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateCompanyDtoGen = {
      address: this.address,
      companyName: this.companyName,
      contact: this.contact,
      groupId: this.groupId,
      isCompany: this.isCompany,
      isTaxDeductible: this.isTaxDeductible,
      language: this.language,
      type: this.type,
      assignees: this.assignees,
      company: this.company,
      firstName: this.firstName,
      lastName: this.lastName,
      taxnumber: this.taxnumber,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || [])
    };
    const res = await companyService.update(this.partnerId, this.id, data);
    this.map(res);
    CompanyDataAccessLayer.set(this);

    return this;
  }

  /**
   * update company via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateCompanyDtoGen) {
    const res = await companyService.update(this.partnerId, this.id, dto);

    this.map(res);

    CompanyDataAccessLayer.set(this);

    return this;
  }
}

type ICompany = CompanyBase;
const Company = Filter.createForClass(CompanyBase);

export { Company, ICompany };
