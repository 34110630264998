














































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomViews from "@/components/cost/CustomViews.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { ICompany, Company } from "@/models/company.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { CompanyModule } from "@/store/modules/company.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import CompanySideCard from "@/components/company/CompanySideCard.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import CompanyCreateDialog from "@/components/company/CompanyCreateDialog.vue";
import { CompanyTypeEnum } from "@/lib/enum/company-type.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { simpleDoubleDigitDate, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { MrfiktivCompanyControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {
    MHeader,
    CustomViews,
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    CompanySideCard,
    RefsVehicle,
    CompanyCreateDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyGroupCustomViewView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Ref("companyDialog")
  companyDialog!: CompanyCreateDialog;

  @Ref("customViews")
  customViews!: CustomViews<ICompany, MrfiktivCompanyControllerGetParamsGen>;

  readonly store = CompanyModule;

  readonly BackendResourceEnum = BackendResourceEnum;

  readonly CompanyTypeEnum = CompanyTypeEnum;

  isLoading = false;

  companyGroup: ICompanyGroup | null = null;

  viewId = "0";

  partnerId = this.$route.params.partnerId;

  companyGroupId = this.$route.params.companyGroupId;

  selectedItem: ICompany | null = null;

  tableBaseConfig = {
    values: [
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "companyName"
      }
    ],
    filters: []
  };

  calendarBaseConfig = {
    calendarStart: {
      type: ProjectCustomViewFieldEnum.PROPERTY,
      key: "date"
    },
    filters: []
  };

  boardBaseConfig = {
    filters: []
  };

  get viewIdLocal() {
    return this.viewId;
  }

  set viewIdLocal(value: string) {
    this.viewId = value;

    this.closeSideCard();
  }

  get breadCrumbList(): IBreadcrumb[] {
    const locations = CompanyGoToHelper.locations;
    const crumbs: IBreadcrumb[] = [
      {
        text: $t("company.companyGroupTable"),
        exact: true,
        to: locations.companyGroupTable({ partnerId: this.partnerId })
      },
      {
        text: this.companyGroup?.title || "",
        exact: false,
        to: locations.companyGroupCustomViewHome({
          partnerId: this.partnerId,
          companyGroupId: this.companyGroupId
        })
      }
    ];

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    return chips;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.canCreateCompany) {
      actions.push({
        text: $t("company.createCompany"),
        key: "createCompany",
        exec: () => this.companyDialog.open()
      });
    }

    if (this.companyGroup?.configuration.views[Number(this.viewIdLocal)]?.type === ProjectCustomViewTypeEnum.TABLE) {
      actions.push({
        text: $t("common.verbs.download").toString(),
        key: "download",
        exec: () => this.customViews.downloadToCsv()
      });
    }

    actions.push({
      icon: "mdi-pencil",
      text: $t("project.ticket.settings"),
      key: "edit",
      exec: this.goToSettings
    });

    return actions;
  }

  goToSettings() {
    new CompanyGoToHelper(this.$router).goToCompanyGroupDetail({
      partnerId: this.partnerId,
      companyGroupId: this.companyGroupId
    });
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.companyGroupId = this.$route.params.companyGroupId;
    this.viewId = this.$route.params.viewId ?? "0";
  }

  async mounted() {
    this.isLoading = true;
    try {
      const partnerAsync = this.trySetByRouteOrDefault();
      const companyGroupAsync = (
        CompanyGroupModule.maps.id.get(this.companyGroupId)[0] ??
        new CompanyGroup({
          id: this.companyGroupId,
          partnerId: this.partnerId
        })
      )
        .fetch()
        .then(companyGroup => (this.companyGroup = companyGroup));

      await Promise.all([partnerAsync, companyGroupAsync]);

      this.store.setHiddenFilter([
        new PageFilterElement({ key: "groupId", operation: "$eq", value: this.companyGroupId })
      ]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    const companyId = this.$route.query.companyId as string;
    if (companyId) {
      const company = new Company({ partnerId: this.partnerId, id: companyId });
      this.openSideCard(company);
    }
  }

  createCompany() {
    this.companyDialog?.open();
  }

  // identify strings formatted as NNNN-NN-NNTNN:NN
  isDayTimeString(value: string) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
  }

  getDateTime(value: string) {
    return simpleDoubleDigitDate(value) + " " + formatHoursAndMinutes(new Date(value));
  }

  goToVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.HOME, true);
  }

  closeSideCard() {
    this.selectedItem = null;

    new CompanyGoToHelper(this.$router).setUrl(
      CompanyGoToHelper.locations.companyGroupCustomView({
        partnerId: this.partnerId,
        companyGroupId: this.companyGroupId,
        viewId: this.viewIdLocal || "0"
      })
    );
  }

  openSideCard(company: ICompany) {
    this.selectedItem = null;
    this.$nextTick(() => {
      this.selectedItem = company;
      new CompanyGoToHelper(this.$router).setUrl(
        CompanyGoToHelper.locations.companyGroupCustomView({
          partnerId: this.partnerId,
          companyGroupId: this.companyGroupId,
          viewId: this.viewIdLocal || "0",
          query: company ? { companyId: company.id } : {}
        })
      );
    });
  }
}
