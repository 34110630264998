

































































































































































































































































































































































































































































































import CompanyGroupCreateDialog from "@/components/company/group/CompanyGroupCreateDialog.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import RefsCompany from "@/components/utility/RefsCompany.vue";
import RefsCompanyGroup from "@/components/utility/RefsCompanyGroup.vue";
import { CompanyTypeEnum } from "@/lib/enum/company-type.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { emailRule, phoneRuleOptional } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Address } from "@/models/address.entity";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { ICompany } from "@/models/company.entity";
import { Contact } from "@/models/contact.entity";
import { CustomFieldValue } from "@/models/custom-field-value.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { debounce } from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import SelectAssignees from "../utility/SelectAssignees.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    ActivityCard: () => import("@/components/thg/ActivityCard.vue"),
    RefsCompanyGroup,
    RefsCompany,
    CustomFieldListForm,
    MActionList,
    ConfirmActionDialog,
    CompanyGroupCreateDialog,
    Debug,
    SelectAssignees,
    CopyClipboardText
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class CompanyCard extends mixins(PartnerFallbackMixin) {
  readonly CompanyTypeEnum = CompanyTypeEnum;
  readonly Contact = Contact;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;

  @Prop()
  value!: ICompany;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  isCompanyValuesDialogActive = false;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  companyGroup: ICompanyGroup | null = null;

  isLoadingGroup = false;

  isLoadingCustomFieldValues = false;

  isLoadingCompanyGroups = false;

  isLoadingCompanyName = false;

  isEditingCompanyName = false;

  editCompanyNameCopy = this.value.companyName;

  isCompanyValuesFormValid = false;

  isAddressFormValid = false;

  isContactFormValid = false;

  editAddress = this.value.address ?? null;

  editContact = this.value.contact ?? null;

  isUpdateCompanyValuesLoading = false;

  isUpdateContactLoading = false;

  isUpdateAddressLoading = false;

  debounceSaveCompanyValues = debounce(() => this.saveCompanyValues(), 1000, false);

  debounceSaveCustomFields = debounce(() => this.saveCustomFieldValues(), 1000, false);

  debounceSaveContact = debounce(() => this.saveContact(), 1000, false);

  debounceSaveAddress = debounce(() => this.saveAddress(), 1000, false);

  isContactDialogActive = false;

  isAddressDialogActive = false;

  editCompanyValues = {
    companyName: this.value.companyName,
    firstName: this.value.firstName,
    lastName: this.value.lastName,
    type: this.value.type,
    language: this.value.language,
    isTaxDeductible: this.value.isTaxDeductible,
    taxnumber: this.value.taxnumber
  };

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [requiredRule(), emailRule()];
  }

  get phoneRule() {
    return [phoneRuleOptional()];
  }

  get subtitle() {
    if (this.value.userId && this.getUserNameForId(this.value.userId)) {
      return $t("createdOnBy", {
        date: simpleDoubleDigitDate(this.value.timestamp.created),
        name: this.getUserNameForId(this.value.userId)
      });
    }

    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const goTo = CompanyGoToHelper.locations;

    return [
      { text: $t("company.companyTable"), exact: true, to: goTo.companyTable({ partnerId: this.value.partnerId }) },
      {
        text: this.value.companyName,
        exact: true,
        to: goTo.companyDetail({ partnerId: this.value.partnerId, companyId: this.value.id })
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    actions.push({
      text: $t("company.editCompanyName"),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startEditingCompanyName
    });

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    const companyGroupId = this.value.groupId;
    if (companyGroupId) {
      actions.push({
        text: $t("company.toCompanyGroup"),
        key: "toCompanyGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
            partnerId: this.value.partnerId,
            companyGroupId: companyGroupId,
            viewId: "0",
            newTab: false
          })
      });
    }

    actions.push({
      text: $t("delete"),
      key: "title",
      icon: "mdi-trash-can",
      color: "red",
      exec: this.onDeleteItem
    });

    return actions;
  }

  get groups() {
    return CompanyGroupModule.entities;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.COMPANY
    };
  }

  mounted() {
    this.setCompanyGroup();
  }

  startEditingCompanyName() {
    this.editCompanyNameCopy = this.value.companyName;
    this.isEditingCompanyName = true;
  }

  cancelEditCompanyName() {
    this.isEditingCompanyName = false;
    this.editCompanyNameCopy = this.value.companyName;
  }

  async saveEditCompanyName() {
    this.isLoadingCompanyName = true;
    this.value.companyName = this.editCompanyNameCopy;
    await this.value
      .updatePartial({
        companyName: this.editCompanyNameCopy
      })
      .catch(handleError);
    this.isLoadingCompanyName = false;
    this.isEditingCompanyName = false;
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  goToCompanyGroupCustomView() {
    if (!this.value.groupId) return;

    new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
      partnerId: this.value.partnerId,
      companyGroupId: this.value.groupId,
      viewId: "0",
      newTab: false
    });
  }

  @Watch("value.groupId")
  async setCompanyGroup() {
    this.companyGroup = null;

    const companyGroupId = this.value.groupId;
    if (companyGroupId) {
      this.isLoadingCustomFieldValues = true;
      const companyGroup =
        CompanyGroupModule.maps.id.get(companyGroupId)[0] ||
        (await new CompanyGroup({ partnerId: this.partner.id, id: companyGroupId }).fetch().catch(handleError));
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.companyGroup = companyGroup;
      });
    }
  }

  async saveGroup() {
    this.isLoadingGroup = true;
    await this.value
      .updatePartial({
        groupId: this.value.groupId
      })
      .catch(handleError);
    this.isLoadingGroup = false;
  }

  async saveCustomFieldValues() {
    this.isLoadingCustomFieldValues = true;
    await this.value
      .updatePartial({
        values: CustomFieldValue.buildCustomFieldValuesDto(
          this.value.values.map(v => ({ id: v.id, value: v.value, timezone: v.timezone }))
        )
      })
      .catch(handleError);
    this.isLoadingCustomFieldValues = false;
  }

  async refreshCompanyGroups() {
    this.isLoadingCompanyGroups = true;
    try {
      CompanyGroupModule.setFilter([]);
      await CompanyGroupModule.fetchAll({ partnerId: PartnerModule.partner.id });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingCompanyGroups = false;
  }

  async saveCompanyValues() {
    if (!this.isCompanyValuesFormValid) throw new Error("Company form is not valid");

    this.isUpdateCompanyValuesLoading = true;

    await this.value
      .updatePartial({
        companyName: this.editCompanyValues.companyName,
        firstName: this.editCompanyValues.firstName,
        lastName: this.editCompanyValues.lastName,
        type: this.editCompanyValues.type,
        language: this.editCompanyValues.language,
        isTaxDeductible: this.editCompanyValues.isTaxDeductible,
        taxnumber: this.editCompanyValues.taxnumber
      })
      .catch(handleError);
    this.editCompanyValues.companyName = this.value.companyName;
    this.editCompanyValues.firstName = this.value.firstName;
    this.editCompanyValues.lastName = this.value.lastName;
    this.editCompanyValues.type = this.value.type;
    this.editCompanyValues.language = this.value.language;
    this.editCompanyValues.isTaxDeductible = this.value.isTaxDeductible;
    this.editCompanyValues.taxnumber = this.value.taxnumber;

    this.isCompanyValuesDialogActive = false;
    this.isUpdateCompanyValuesLoading = false;
  }

  async saveContact() {
    if (!this.isContactFormValid) throw new Error("Contact form is not valid");

    this.isUpdateContactLoading = true;

    await this.value
      .updatePartial({
        contact: this.editContact
      })
      .catch(handleError);
    this.editContact = this.value.contact || null;

    this.isContactDialogActive = false;
    this.isUpdateContactLoading = false;
  }

  async saveAddress() {
    if (!this.isAddressFormValid) throw new Error("Address form is not valid");

    this.isUpdateAddressLoading = true;

    await this.value
      .updatePartial({
        address: this.editAddress
      })
      .catch(handleError);
    this.editAddress = this.value.address || null;

    this.isAddressDialogActive = false;
    this.isUpdateAddressLoading = false;
  }
}
