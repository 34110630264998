var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.companyGroup ? _c('div', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.companyGroup.title,
      "actions": _vm.actions,
      "viewId": _vm.viewIdLocal,
      "chips": _vm.chips
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "actionClicked": function actionClicked(action) {
        return action.exec();
      }
    }
  }), _c('custom-views', {
    ref: "customViews",
    attrs: {
      "store": _vm.store,
      "entity": _vm.companyGroup,
      "viewId": _vm.viewIdLocal,
      "refType": _vm.BackendResourceEnum.COMPANY,
      "boardBaseConfig": _vm.boardBaseConfig,
      "calendarBaseConfig": _vm.calendarBaseConfig,
      "tableBaseConfig": _vm.tableBaseConfig
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "openSideCard": _vm.openSideCard,
      "goToSettings": _vm.goToSettings
    },
    scopedSlots: _vm._u([{
      key: "item.companyName",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openSideCard(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.companyName) + " ")])];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }])
  })], 1) : _c('latest-entries-card-empty'), _vm.selectedItem ? _c('company-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": _vm.closeSideCard
    }
  }) : _vm._e(), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.COMPANY
    }
  }, [_c('company-create-dialog', {
    ref: "companyDialog",
    attrs: {
      "hideButton": true,
      "partnerId": _vm.partnerId,
      "groupId": _vm.companyGroupId
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }