
























import { handleError } from "@/lib/utility/handleError";
import { ICompany } from "@/models/company.entity";
import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import SideCard from "../utility/SideCard.vue";
import CompanyCard from "./CompanyCard.vue";

@Component({
  components: { SideCard, CompanyCard, LatestEntriesCardEmpty }
})
export default class CompanySideCard extends mixins(Vue) {
  @Prop()
  value!: ICompany;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  isLoading = false;

  async mounted() {
    try {
      this.isLoading = true;

      await this.value.fetch();
    } catch (error) {
      handleError(error);
      this.close();
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.$emit("close");
  }
}
