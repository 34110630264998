



































































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { CompanyTypeEnum } from "@/lib/enum/company-type.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { emailRule, phoneRuleOptional } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Address } from "@/models/address.entity";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { Company, ICompany } from "@/models/company.entity";
import { Contact } from "@/models/contact.entity";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { ConfigModule } from "@/store/modules/config";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsCompanyGroup from "../utility/RefsCompanyGroup.vue";
import SelectAssignees from "../utility/SelectAssignees.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    RefsCompanyGroup,
    CustomFieldListForm,
    LatestEntriesCardEmpty,
    Debug,
    SelectAssignees
  },
  filters: {
    simpleDoubleDigitDate,
    getFlagEmojiByLanguage
  }
})
export default class CompanyCreateDialog extends mixins(PartnerFallbackMixin) implements IDialog {
  readonly CompanyTypeEnum = CompanyTypeEnum;
  readonly Contact = Contact;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  /**
   * ID of company group to preselect
   */
  @Prop()
  groupId?: string;

  @Prop({ default: false })
  fullscreen?: boolean;

  isDialogActive = false;

  isLoading = false;

  isFormValid = false;

  company: ICompany = new Company({
    groupId: this.groupId || ""
  });

  companyGroup: ICompanyGroup | null = null;

  isLoadingCustomFieldValues = false;

  isLoadingCompanyGroups = false;

  get groups() {
    return CompanyGroupModule.entities;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [requiredRule(), emailRule()];
  }

  get phoneRule() {
    return [phoneRuleOptional()];
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  open() {
    this.company = new Company({
      partnerId: this.partnerId,
      groupId: this.groupId || ""
    });
    this.setCompanyGroup();
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.companyNameField as HTMLInputElement)?.focus();
    });
  }

  close() {
    this.isDialogActive = false;
  }

  async onRightClick() {
    try {
      this.isDialogActive = true;
      await this.company.create();
      this.$emit("created", this.company);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
    }
  }

  @Watch("company.groupId")
  async setCompanyGroup() {
    this.companyGroup = null;

    const companyGroupId = this.company.groupId;
    if (companyGroupId) {
      this.isLoadingCustomFieldValues = true;
      const companyGroup =
        CompanyGroupModule.maps.id.get(companyGroupId)[0] ||
        (await new CompanyGroup({ partnerId: this.company.partnerId, id: companyGroupId }).fetch().catch(handleError));
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.companyGroup = companyGroup;
      });
    }
  }

  onRefsChange() {
    this.$log.debug("onRefsChange");
  }

  goToCompanyGroupCustomView() {
    if (!this.company.groupId) return;

    new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
      partnerId: this.partnerId,
      companyGroupId: this.company.groupId,
      viewId: "0",
      newTab: true
    });
  }

  async refreshCompanyGroups() {
    this.isLoadingCompanyGroups = true;
    try {
      CompanyGroupModule.setFilter([]);
      await CompanyGroupModule.fetchAll({ partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingCompanyGroups = false;
  }
}
