




























































































import Debug from "@/components/utility/Debug.vue";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { CompanyTypeEnum } from "@/lib/enum/company-type.enum";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { CompanyGroup } from "@/models/company-group.entity";
import { ICompany } from "@/models/company.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { CompanyModule } from "@/store/modules/company.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsCompany from "../utility/RefsCompany.vue";
import RefsCompanyGroup from "../utility/RefsCompanyGroup.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CompanyCreateDialog from "./CompanyCreateDialog.vue";
import CompanySideCard from "./CompanySideCard.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CompanyCreateDialog,
    CompanySideCard,
    RefsCompanyGroup,
    RefsCompany,
    Debug,
    RefsVehicle,
    AssigneePreview
  }
})
export default class CompanyTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = CompanyModule;

  readonly CompanyTypeEnum = CompanyTypeEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId!: string;

  selectedItem: ICompany | null = null;

  selectedItemForDelete: ICompany | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.company.type"),
      value: "type"
    });
    headers.push({
      text: $t("objects.company.companyName"),
      value: "companyName"
    });
    headers.push({
      text: $t("company.contact"),
      value: "contact"
    });
    headers.push({
      text: $t("common.nouns.address"),
      value: "address"
    });
    headers.push({
      text: $t("objects.company.groupId"),
      value: "groupId"
    });
    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.createdReadable"
    });
    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: ICompany) =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            companyId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  async getCompanyGroup(id: string) {
    if (!id) return;
    let companyGroup = CompanyGroupModule.maps.id.get(id)[0];
    if (!companyGroup) {
      companyGroup = new CompanyGroup({ partnerId: this.partnerId, id });
      companyGroup.fetch();
    }

    return companyGroup;
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: ICompany | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: ICompany) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
