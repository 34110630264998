










import CompanyTable from "@/components/company/CompanyTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CompanyModule } from "@/store/modules/company.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { handleError } from "@/lib/utility/handleError";
import { ICompany, Company } from "@/models/company.entity";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    CompanyTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyTableView extends mixins(PartnerFallbackMixin) {
  @Ref("companyTable")
  companyTable!: CompanyTable;

  readonly AMOUNT_COST = "amountCompany";

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(CompanyGoToHelper.breadCrumbs.CompanyTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${CompanyModule.totalItems} ${this.$t("company.companies")}`,
      key: this.AMOUNT_COST,
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    CompanyModule.setHiddenFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    await CustomFieldModule.fetchAllFromBeginning({ partnerId: this.partnerId }).catch(handleError);

    const companyId = this.$route.query.companyId as string;
    if (companyId) {
      const company = new Company({ partnerId: this.partnerId, id: companyId });
      this.companyTable?.setSelectedItem(company);
    }
  }

  openSideCard(company: ICompany) {
    new CompanyGoToHelper(this.$router).setUrl(
      CompanyGoToHelper.locations.companyTable({
        partnerId: this.partnerId,
        query: company ? { companyId: company.id } : {}
      })
    );
  }
}
