import { permissionGuard } from "@/auth/permissionAuthGuard";
import ApiComponent from "@/components/development/ApiComponent.vue";
import PartnerMessageInbox from "@/components/partner/PartnerMessageInbox.vue";
import PartnerMessageInboxList from "@/components/partner/PartnerMessageInboxList.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import { NavigationRoute } from "@/store/mapper/navigation.route";
import { FeatureModule } from "@/store/modules/feature.store";
import Error from "@/views/Error.vue";
import Imprint from "@/views/Imprint.vue";
import NoRoles from "@/views/NoRoles.vue";
import OperationView from "@/views/OperationView.vue";
import PartnerCreate from "@/views/PartnerCreate.vue";
import PartnerCustomerList from "@/views/PartnerCustomerList.vue";
import PartnerFaqDetail from "@/views/PartnerFaqDetail.vue";
import PartnerFaqList from "@/views/PartnerFaqList.vue";
import Login from "@/views/PartnerLogin.vue";
import PartnerUserDetailView from "@/views/PartnerUserDetailView.vue";
import PartnerUserList from "@/views/PartnerUserList.vue";
import Privacy from "@/views/Privacy.vue";
import Terms from "@/views/Terms.vue";
import EventDetailView from "@/views/fleet/EventDetailView.vue";
import EventFormView from "@/views/fleet/EventFormView.vue";
import ActivityLogTable from "@/views/portal/ActivityLogTable.vue";
import CustomFieldDetail from "@/views/portal/CustomFieldDetail.vue";
import CustomFieldList from "@/views/portal/CustomFieldList.vue";
import CustomerAccountDetailView from "@/views/portal/CustomerAccountDetailView.vue";
import ExcelImportPlayground from "@/views/portal/ExcelImportPlayground.vue";
import PartnerAnalyticsHome from "@/views/portal/PartnerAnalyticsHome.vue";
import PartnerCalendar from "@/views/portal/PartnerCalendar.vue";
import PartnerDaTableView from "@/views/portal/PartnerDaTableView.vue";
import PartnerDashboard from "@/views/portal/PartnerDashboard.vue";
import PartnerDetailView from "@/views/portal/PartnerDetailView.vue";
import PartnerEvents from "@/views/portal/PartnerEvents.vue";
import PartnerFinderView from "@/views/portal/PartnerFinderView.vue";
import PartnerForms from "@/views/portal/PartnerForms.vue";
import PartnerFormsDetailView from "@/views/portal/PartnerFormsDetailView.vue";
import PartnerLinkerView from "@/views/portal/PartnerLinkerView.vue";
import PartnerManage from "@/views/portal/PartnerManage.vue";
import PartnerManageDetailView from "@/views/portal/PartnerManageDetailView.vue";
import PartnerManageStepperDAView from "@/views/portal/PartnerManageStepperDAView.vue";
import PartnerManageStepperDealerDeskView from "@/views/portal/PartnerManageStepperDealerDeskView.vue";
import PartnerManageStepperKSRView from "@/views/portal/PartnerManageStepperKSRView.vue";
import PartnerManageStepperPDRView from "@/views/portal/PartnerManageStepperPDRView.vue";
import PartnerMessagesDetailView from "@/views/portal/PartnerMessagesDetailView.vue";
import PartnerOperations from "@/views/portal/PartnerOperations.vue";
import PartnerPreviewScreens from "@/views/portal/PartnerPreviewScreens.vue";
import PartnerReportInitialize from "@/views/portal/PartnerReportInitialize.vue";
import PartnerReportTable from "@/views/portal/PartnerReportTable.vue";
import PartnerReports from "@/views/portal/PartnerReports.vue";
import PartnerReportsDetailView from "@/views/portal/PartnerReportsDetailView.vue";
import PartnerSettings from "@/views/portal/PartnerSettings.vue";
import PartnerSharedContentAccessDetail from "@/views/portal/PartnerSharedContentAccessDetail.vue";
import PartnerSharedReport from "@/views/portal/PartnerSharedReport.vue";
import PartnerTable from "@/views/portal/PartnerTable.vue";
import PartnerPermissions from "@/views/portal/PartnerUnauthorized.vue";
import Partners from "@/views/portal/Partners.vue";
import PermissionTable from "@/views/portal/PermissionTable.vue";
import PortalErrorPage from "@/views/portal/PortalErrorPage.vue";
import PreviewScreens from "@/views/portal/PreviewScreens.vue";
import Pricing from "@/views/portal/Pricing.vue";
import QuickLinks from "@/views/portal/QuickLinks.vue";
import ReportControlView from "@/views/portal/ReportControlView.vue";
import SharedContentView from "@/views/portal/SharedContentView.vue";
import Test from "@/views/portal/Test.vue";
import UserAdministration from "@/views/portal/UserAdministration.vue";
import UserAdministrationDetailView from "@/views/portal/UserAdministrationDetailView.vue";
import UserTable from "@/views/portal/UserTable.vue";
import DesignGuidelines from "@/views/portal/designbook/DesignGuidelines.vue";
import DesignGuidelinesHeaderExample from "@/views/portal/designbook/DesignGuidelinesHeaderExample.vue";
import DesignGuidelinesObjectDetailExample from "@/views/portal/designbook/DesignGuidelinesObjectDetailExample.vue";
import DesignGuidelinesPortalMobileOptimizedExample from "@/views/portal/designbook/DesignGuidelinesPortalMobileOptimizedExample.vue";
import RatingView from "@/views/rating/RatingView.vue";
import DigitalSignatureHomeView from "@/views/sign/DigitalSignatureHomeView.vue";
import DocumentTableView from "@/views/sign/DocumentTableView.vue";
import SignRequestDetail from "@/views/sign/SignRequestDetail.vue";
import SignRequestTableView from "@/views/sign/SignRequestTableView.vue";
import TemplateDetailView from "@/views/thgPortal/TemplateDetailView.vue";
import TemplateListView from "@/views/thgPortal/TemplateListView.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { anonymizationRouter } from "./anonymization.router";
import { BookingRouter } from "./booking.router";
import { costRoutes } from "./cost.router";
import { credentialRoutes } from "./credential.router";
import { routes as fahrzeugscheinRoutes } from "./fahrzeugschein.router";
import { FleetRoutes } from "./fleet.router";
import { HandoverRoutes } from "./handover.router";
import { getLoginRoutes } from "./login.router";
import { routes as profileRoutes } from "./profile.router";
import { projectRoutes } from "./project.router";
import { SettingRoutes } from "./settings.router";
import { thirdPartySystemRoutes } from "./third-party-system.router";
import { webhookConfigRouter } from "./webhook-config.router";
import { companyRoutes } from "./company.router";

const DocumentDetail = () => import("@/views/sign/DocumentDetail.vue");
const DocumentTemplateDetailDebug = () => import("@/views/sign/DocumentTemplateDetailDebug.vue");
const DocumentTemplateDetailForm = () => import("@/views/sign/DocumentTemplateDetailForm.vue");
const DocumentTemplateDetailViewer = () => import("@/views/sign/DocumentTemplateDetailViewer.vue");

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [];

fahrzeugscheinRoutes.forEach(route => {
  route.path = "/fahrzeugschein" + route.path;
  routes.push(route);
});

profileRoutes.forEach(route => {
  routes.push(route);
});

export const adminRoutes: Array<ICustomRouteConfig> = [
  {
    path: "/partners/create",
    name: "PartnersCreate",
    component: PartnerCreate,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/partners/table",
    name: "PartnersTable",
    component: PartnerTable,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/partner/create",
    name: "PartnerCreate",
    component: PartnerCreate,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing
  },
  {
    path: "/user",
    name: "UserAdministration",
    component: UserAdministration,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-account-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/user/table",
    name: "UserTable",
    component: UserTable,
    permission: {
      resource: ResourceEnum.USER,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/user/:userId",
    name: "UserAdministrationDetail",
    component: UserAdministrationDetailView,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/partners/:partnerId/customer/:customerAccountId",
    name: "CustomerAccountDetailView",
    component: CustomerAccountDetailView,
    permission: {
      resource: ResourceEnum.CUSTOMER_DATA,
      action: ActionEnum.READ
    }
  },
  {
    path: "/user/:userId/permission",
    name: "PermissionTable",
    component: PermissionTable,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/template/:key",
    name: "TemplateDetailView",
    component: TemplateDetailView,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/operation/:operationId",
    name: "OperationView",
    component: OperationView,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/template",
    name: "TemplateListView",
    component: TemplateListView,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-email-edit-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/excelimportplayground",
    name: "ExcelImportPlayground",
    component: ExcelImportPlayground,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-database-import-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/design-guidelines",
    name: "DesignGuidelines",
    component: DesignGuidelines,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-database-import-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/design-guidelines/header-example",
    name: "DesignGuidelinesHeaderExample",
    component: DesignGuidelinesHeaderExample,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-database-import-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: false
    }
  },
  {
    path: "/design-guidelines/mobile-optimized-example",
    name: "DesignGuidelinesPortalMobileOptimizedExample",
    component: DesignGuidelinesPortalMobileOptimizedExample,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-database-import-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: false
    }
  },
  {
    path: "/design-guidelines/header-example/detail",
    name: "DesignGuidelinesObjectDetailExample",
    component: DesignGuidelinesObjectDetailExample,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-database-import-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: false
    }
  },
  {
    path: "/rating",
    name: "RatingView",
    component: RatingView,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-star",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  }
];

export const portalRoutes: Array<ICustomRouteConfig> = [
  {
    path: "/partners",
    name: "Partners",
    component: Partners,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ
    }
  },
  {
    path: "/partners/list",
    name: "PartnersList",
    component: Partners,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-file-multiple-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 100
    }
  },
  {
    path: "/partner/list",
    name: "PartnerList",
    component: Partners,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ
    }
  },
  {
    path: "/partner/table",
    name: "PartnerTable",
    component: PartnerTable,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ
    }
  },
  {
    path: "/partners/:partnerId/home",
    name: "PartnerDashboard",
    component: PartnerDashboard,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-view-dashboard-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 100
    }
  },
  {
    path: "/partners/:partnerId/user",
    name: "PartnerUserList",
    component: PartnerUserList,
    permission: {
      resource: ResourceEnum.USER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-account-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/user/:userId",
    name: "PartnerUserDetailView",
    component: PartnerUserDetailView,
    permission: {
      resource: ResourceEnum.USER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/customer",
    name: "PartnerCustomerList",
    component: PartnerCustomerList,
    permission: {
      resource: ResourceEnum.CUSTOMER_DATA,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      icon: "mdi-account-details-outline",
      category: NavigationCategoryEnum.HOME,
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/form",
    name: "PartnerForms",
    component: PartnerForms,
    permission: {
      resource: ResourceEnum.FORM,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-message-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/form/:formId",
    name: "PartnerFormsDetailView",
    component: PartnerFormsDetailView,
    permission: {
      resource: ResourceEnum.FORM,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/report",
    name: "PartnerReports",
    component: PartnerReports,
    permission: {
      resource: ResourceEnum.REPORT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-car-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partners/:partnerId/report/table",
    name: "PartnerReportTable",
    component: PartnerReportTable,
    permission: {
      resource: ResourceEnum.REPORT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-car-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    permission: {
      resource: ResourceEnum.ALL,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/partners/:partnerId/analytics",
    name: "PartnerAnalytics",
    component: PartnerAnalyticsHome,
    permission: {
      resource: ResourceEnum.ANALYTICS,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.ANALYTICS,
      icon: "mdi-chart-line",
      isEnabled: FeatureModule.isPartnerAnalyticsEnabled,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/event",
    name: "PartnerEvents",
    component: PartnerEvents,
    permission: {
      resource: ResourceEnum.EVENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-calendar",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/event/overdue",
    name: "PartnerEventsOverdue",
    component: PartnerEvents,
    permission: {
      resource: ResourceEnum.EVENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/event/list",
    name: "PartnerEventsList",
    component: PartnerEvents,
    permission: {
      resource: ResourceEnum.EVENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/event/series",
    name: "PartnerEventsRecurring",
    component: PartnerEvents,
    permission: {
      resource: ResourceEnum.EVENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/event/:eventId",
    name: "EventDetailView",
    component: EventDetailView,
    permission: {
      resource: ResourceEnum.EVENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-calendar",
      isEnabled: false,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/event/:eventId/detail",
    name: "EventFormView",
    component: EventFormView,
    permission: {
      resource: ResourceEnum.EVENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-calendar",
      isEnabled: false,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/calendar",
    name: "PartnerCalendar",
    component: PartnerCalendar
  },
  {
    path: "/partners/:partnerId/report/new",
    name: "PartnerReportInitialize",
    component: PartnerReportInitialize,
    permission: {
      resource: ResourceEnum.REPORT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/report/:reportId/accesssignature/:accesssignature",
    name: "PartnerSharedReport",
    component: PartnerSharedReport
  },
  {
    path: "/partners/:partnerId/report/:reportId",
    name: "PartnerReportsDetailView",
    component: PartnerReportsDetailView,
    permission: {
      resource: ResourceEnum.REPORT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/inbox",
    name: "PartnerMessageInboxList",
    component: PartnerMessageInboxList,
    permission: {
      resource: ResourceEnum.MESSAGE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-inbox-arrow-down",
      isEnabled: FeatureModule.isInboxEnabled,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/inbox/:inboxId",
    name: "PartnerMessageInbox",
    component: PartnerMessageInbox,
    permission: {
      resource: ResourceEnum.MESSAGE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-inbox-arrow-down",
      isEnabled: FeatureModule.isInboxEnabled,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/message/:messageId",
    name: "PartnerMessagesDetailView",
    component: PartnerMessagesDetailView,
    permission: {
      resource: ResourceEnum.MESSAGE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/document",
    name: "DigitalSignatureHomeView",
    component: DigitalSignatureHomeView,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SIGN,
      icon: "mdi-file-document-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    // Tab can be ResourceEnum.SIGN or ResourceEnum.DOCUMENT
    path: "/partners/:partnerId/document/tab/:tab",
    name: "DigitalSignatureHomeViewTab",
    component: DigitalSignatureHomeView,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/document/list",
    name: "DocumentTableView",
    component: DocumentTableView,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SIGN,
      icon: "mdi-file-document-multiple-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/document/:documentId",
    name: "DocumentDetailView",
    component: DocumentDetail,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/document/:documentId/template/debug",
    name: "DocumentTemplateDetailDebugView",
    component: DocumentTemplateDetailDebug,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/document/:documentId/template/form",
    name: "DocumentTemplateDetailFormView",
    component: DocumentTemplateDetailForm,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/document/:documentId/template/view",
    name: "DocumentTemplateDetailViewerView",
    component: DocumentTemplateDetailViewer,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/sign-request",
    name: "SignRequestTable",
    component: SignRequestTableView,
    permission: {
      resource: ResourceEnum.SIGN,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SIGN,
      icon: "mdi-file-sign",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/sign-request/:signRequestId",
    name: "SignRequestDetailView",
    component: SignRequestDetail,
    permission: {
      resource: ResourceEnum.SIGN,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  // Replicating for compatibility
  {
    path: "/partner/:partnerId/sign-request",
    name: "PartnerSignRequestTable",
    component: SignRequestTableView,
    permission: {
      resource: ResourceEnum.SIGN,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/sign-request/:signRequestId",
    name: "PartnerSignRequestDetailView",
    component: SignRequestDetail,
    permission: {
      resource: ResourceEnum.SIGN,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/activity-log",
    name: "ActivityLogTable",
    component: ActivityLogTable,
    //TODO: Discuss permission
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-cog-clockwise",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/custom-field",
    name: "CustomFieldList",
    component: CustomFieldList,
    permission: {
      resource: ResourceEnum.CUSTOM_FIELD,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-focus-field-horizontal",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/custom-field/:customFieldId",
    name: "CustomFieldDetailView",
    component: CustomFieldDetail,
    permission: {
      resource: ResourceEnum.CUSTOM_FIELD,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/manage",
    name: "PartnerManage",
    component: PartnerManage,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-file-cog-outline",
      isEnabled: FeatureModule.isPartnerManagerEnabled,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: true
    }
  },
  {
    path: "/partners/:partnerId/manage/:settingId",
    name: "PartnerManageDetailView",
    component: PartnerManageDetailView,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/stepper/da",
    name: "PartnerManageStepperDAView",
    component: PartnerManageStepperDAView,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/stepper/ksr",
    name: "PartnerManageStepperKSRView",
    component: PartnerManageStepperKSRView,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/stepper/dealerdesk",
    name: "PartnerManageStepperDealerDeskView",
    component: PartnerManageStepperDealerDeskView,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/stepper/pdr",
    name: "PartnerManageStepperPDRView",
    component: PartnerManageStepperPDRView,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/da",
    name: "PartnerDaTableView",
    component: PartnerDaTableView,
    permission: {
      resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-webhook",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/faq/:faqId/",
    name: "PartnerFaqDetail",
    component: PartnerFaqDetail,
    permission: {
      resource: ResourceEnum.FAQ,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/faq/",
    name: "PartnerFaqList",
    component: PartnerFaqList,
    permission: {
      resource: ResourceEnum.FAQ,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-comment-question-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/operation/:operationId",
    name: "OperationPartnerView",
    component: OperationView,
    permission: {
      resource: ResourceEnum.OPERATION,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/template",
    name: "TemplateListPartnerView",
    component: TemplateListView,
    permission: {
      resource: ResourceEnum.TEMPLATE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-email-edit-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/template/:key",
    name: "TemplateDetailPartnerView",
    component: TemplateDetailView,
    permission: {
      resource: ResourceEnum.TEMPLATE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/operation",
    name: "PartnerOperations",
    component: PartnerOperations,
    permission: {
      resource: ResourceEnum.OPERATION,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-swap-vertical-bold",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },

  {
    path: "/partners/:partnerId/control",
    name: "ReportControlView",
    component: ReportControlView,
    permission: {
      resource: ResourceEnum.REPORT_CONTROL,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.HOME,
      icon: "mdi-file-send-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/linker",
    name: "PartnerLinkerView",
    component: PartnerLinkerView,
    permission: {
      resource: ResourceEnum.PARTNER_LINK,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-link-variant",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/linker/finder",
    name: "PartnerFinderView",
    component: PartnerFinderView,
    permission: {
      resource: ResourceEnum.PARTNER_LINK,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-map-search-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId",
    name: "PartnerDetailView",
    component: PartnerDetailView,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ
    }
  },
  {
    path: "/partners/:partnerId/shared",
    name: "SharedContentView",
    component: SharedContentView,
    permission: {
      resource: ResourceEnum.SHARED_CONTENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-share-all-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/shared/:sharedContentId",
    name: "SharedContentDetailView",
    component: SharedContentView,
    permission: {
      resource: ResourceEnum.SHARED_CONTENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/shared/:sharedContentId/access/:accessToken",
    name: "PartnerSharedContentAccessDetail",
    component: PartnerSharedContentAccessDetail
  },
  {
    path: "/partners/:partnerId/quick-links",
    name: "QuickLinks",
    component: QuickLinks,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SEARCH,
      icon: "mdi-magnify",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: false,
      isProfileMenu: true
    }
  },
  {
    path: "/partners/:partnerId/preview",
    name: "PartnerPreviewScreens",
    component: PartnerPreviewScreens,
    permission: {
      resource: ResourceEnum.REPORT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-file-find",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/error",
    name: "PartnerError",
    component: PortalErrorPage
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: PartnerPermissions
  },

  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  },
  {
    path: "/errors",
    name: "Error",
    component: Error
  },
  {
    path: "/partner-setting",
    name: "PartnerSettings",
    component: PartnerSettings
  },
  {
    path: "/agbs",
    name: "AGBs",
    component: Terms,
    navigation: {
      category: NavigationCategoryEnum.HELP,
      icon: "mdi-information-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/privacy",
    name: "Datenschutz",
    component: Privacy,
    navigation: {
      category: NavigationCategoryEnum.HELP,
      icon: "mdi-shield-sun-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/imprint",
    name: "Impressum",
    component: Imprint,
    navigation: {
      category: NavigationCategoryEnum.HELP,
      icon: "mdi-handshake-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/preview",
    name: "PreviewScreens",
    component: PreviewScreens
  },
  {
    path: "*",
    name: "PartnerHome",
    component: PartnerDashboard,
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ
    }
  }
];

adminRoutes.forEach(route => {
  routes.push(route);
});

portalRoutes.forEach(route => {
  routes.push(route);
});

projectRoutes.forEach(route => {
  routes.push(route);
});

costRoutes.forEach(route => {
  routes.push(route);
});

companyRoutes.forEach(route => {
  routes.push(route);
});

thirdPartySystemRoutes.forEach(route => {
  routes.push(route);
});

credentialRoutes.forEach(route => {
  routes.push(route);
});

anonymizationRouter.forEach(route => {
  routes.push(route);
});

webhookConfigRouter.forEach(route => {
  routes.push(route);
});

getLoginRoutes(Login).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

FleetRoutes.forEach((route: RouteConfig) => {
  routes.push(route);
});

HandoverRoutes.forEach((route: RouteConfig) => {
  routes.push(route);
});

BookingRouter.forEach((route: RouteConfig) => {
  routes.push(route);
});

SettingRoutes.forEach((route: RouteConfig) => {
  route.path = "/settings" + route.path;
  routes.push(route);
});

routes.push({
  path: "/api",
  name: "api",
  component: ApiComponent
});

export function portalRouteConfig(): NavigationRoute[] {
  const portalRouteConfig: NavigationRoute[] = [];
  for (const route of routes) {
    portalRouteConfig.push(new NavigationRoute(route));
  }
  return portalRouteConfig;
}

const router = new VueRouter({
  routes,
  linkExactActiveClass: "router-link-exact-path-active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(permissionGuard);

export default router;
