var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e(), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t('company.createCompany'),
      "isDialogActive": _vm.isDialogActive,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "fullscreen": _vm.fullscreen,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.isFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.close,
      "close": _vm.close,
      "rightClick": _vm.onRightClick
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "pt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "companyNameField",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.company.companyName') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.company.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "companyName", $$v);
      },
      expression: "company.companyName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.company.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "firstName", $$v);
      },
      expression: "company.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.company.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "lastName", $$v);
      },
      expression: "company.lastName"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": Object.values(_vm.CompanyTypeEnum),
      "label": _vm.$t('objects.company.type'),
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }], null, false, 1731429820),
    model: {
      value: _vm.company.type,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "type", $$v);
      },
      expression: "company.type"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.availableLanguages,
      "label": _vm.$t('objects.company.language'),
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " ")];
      }
    }], null, false, 895571804),
    model: {
      value: _vm.company.language,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "language", $$v);
      },
      expression: "company.language"
    }
  }), _c('v-switch', {
    staticClass: "mt-n2",
    attrs: {
      "label": _vm.$t('objects.company.isTaxDeductible')
    },
    model: {
      value: _vm.company.isTaxDeductible,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "isTaxDeductible", $$v);
      },
      expression: "company.isTaxDeductible"
    }
  }), _vm.company.isTaxDeductible ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.taxnumber'),
      "outlined": ""
    },
    model: {
      value: _vm.company.taxnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "taxnumber", $$v);
      },
      expression: "company.taxnumber"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('select-assignees', {
    attrs: {
      "css": "width: 100%",
      "outlined": true,
      "dense": false
    },
    model: {
      value: _vm.company.assignees,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "assignees", $$v);
      },
      expression: "company.assignees"
    }
  }), !_vm.company.contact ? _c('v-card', {
    staticClass: "d-flex mb-8",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.company.contact = new _vm.Contact();
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    }
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("company.addContact")) + " ")])], 1)], 1) : _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.company.contact = undefined;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("company.removeContact")) + " ")])], 1), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.emailRule,
      "label": _vm.$t('objects.company.contact.email') + ' *',
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.company.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.company.contact, "email", $$v);
      },
      expression: "company.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.phoneRule,
      "label": _vm.$t('objects.company.contact.phone'),
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.company.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.company.contact, "phone", $$v);
      },
      expression: "company.contact.phone"
    }
  })], 1), !_vm.company.address ? _c('v-card', {
    staticClass: "d-flex mb-8",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.company.address = new _vm.Address();
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    }
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("company.addAddress")) + " ")])], 1)], 1) : _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.company.address = undefined;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("company.removeContact")) + " ")])], 1), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.street') + ' *',
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.company.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.company.address, "street", $$v);
      },
      expression: "company.address.street"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.zip') + ' *',
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.company.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.company.address, "zip", $$v);
      },
      expression: "company.address.zip"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.city') + ' *',
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.company.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.company.address, "city", $$v);
      },
      expression: "company.address.city"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.state') + ' *',
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.company.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.company.address, "state", $$v);
      },
      expression: "company.address.state"
    }
  }), _c('v-autocomplete', {
    staticClass: "mb-4",
    attrs: {
      "items": Object.values(_vm.CountryCodeEnum),
      "label": _vm.$t('objects.address.countryCode'),
      "auto-select-first": "",
      "dense": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }], null, false, 1731429820),
    model: {
      value: _vm.company.address.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.company.address, "countryCode", $$v);
      },
      expression: "company.address.countryCode"
    }
  })], 1), _c('v-autocomplete', {
    attrs: {
      "item-value": "id",
      "items": _vm.groups,
      "label": _vm.$t('objects.company.groupId') + ' *',
      "rules": _vm.requiredRule,
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }], null, false, 2714690748),
    model: {
      value: _vm.company.groupId,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "groupId", $$v);
      },
      expression: "company.groupId"
    }
  }), _c('div', {
    staticClass: "mt-n7",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0,
      "loading": _vm.isLoadingCompanyGroups
    },
    on: {
      "click": _vm.refreshCompanyGroups
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0
    },
    on: {
      "click": _vm.goToCompanyGroupCustomView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.companyGroup && _vm.companyGroup.configuration && _vm.companyGroup.configuration.customFieldConfig && _vm.companyGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('custom-field-list-form', {
    staticClass: "mt-6",
    attrs: {
      "customFieldConfig": _vm.companyGroup.configuration.customFieldConfig
    },
    model: {
      value: _vm.company.values,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "values", $$v);
      },
      expression: "company.values"
    }
  }), _c('debug', [_vm._v(" company.values: " + _vm._s(_vm.company.values)), _c('br'), _vm._v(" company.groupId: " + _vm._s(_vm.company.groupId)), _c('br'), _vm._v(" companyGroup: " + _vm._s(_vm.companyGroup)), _c('br')])], 1) : _vm._e()], 1)], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.company) + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }