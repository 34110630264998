var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.value.companyName,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.isEditingCompanyName ? [] : _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    },
    scopedSlots: _vm._u([_vm.isEditingCompanyName ? {
      key: "title",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-1 mb-n1",
          attrs: {
            "label": _vm.$t('common.nouns.title'),
            "outlined": "",
            "loading": _vm.isLoadingCompanyName,
            "readonly": _vm.isLoadingCompanyName
          },
          model: {
            value: _vm.editCompanyNameCopy,
            callback: function callback($$v) {
              _vm.editCompanyNameCopy = $$v;
            },
            expression: "editCompanyNameCopy"
          }
        }), _c('div', {
          staticClass: "mt-n6"
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info"
          },
          on: {
            "click": _vm.saveEditCompanyName
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelEditCompanyName
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }), _c('v-divider', {
    staticClass: "mt-2 mb-n4"
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('select-assignees', {
          attrs: {
            "css": "width: 100%",
            "outlined": true,
            "dense": false
          },
          model: {
            value: _vm.value.assignees,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "assignees", $$v);
            },
            expression: "value.assignees"
          }
        }), _vm.isUpdateCompanyValuesLoading ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _vm._e(), _c('v-card', {
          staticClass: "mb-8",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-list-item', {
          staticClass: "pa-0",
          staticStyle: {
            "min-height": "56px"
          },
          on: {
            "click": function click() {
              _vm.isCompanyValuesDialogActive = true;
            }
          }
        }, [_c('v-simple-table', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.general")) + " ")]), _c('th', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "mb-n3 mt-3",
          attrs: {
            "x-small": "",
            "text": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")])], 1)])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.type")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.$t("enums.CompanyTypeEnum.".concat(_vm.value.type))
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.firstName")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.firstName
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.lastName")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.lastName
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.language")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.$t("enums.LanguageCodeEnum.".concat(_vm.value.language))
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.isTaxDeductible")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.isTaxDeductible ? _vm.$t('yes') : _vm.$t('no')
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.taxnumber")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.taxnumber
          }
        })], 1)])])])], 1)], 1), _vm.isUpdateContactLoading ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _vm._e(), _c('v-card', {
          staticClass: "mb-8",
          attrs: {
            "outlined": ""
          }
        }, [_vm.value.contact ? _c('v-list-item', {
          staticClass: "pa-0",
          staticStyle: {
            "min-height": "56px"
          },
          on: {
            "click": function click() {
              _vm.editContact = _vm.value.contact;
              _vm.isContactDialogActive = true;
            }
          }
        }, [_c('v-simple-table', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.contact")) + " ")]), _c('th', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "mb-n3 mt-3",
          attrs: {
            "x-small": "",
            "text": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")])], 1)])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.contact.email")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.contact.email
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.company.contact.phone")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.contact.phone
          }
        })], 1)])])])], 1) : _c('v-list-item', {
          staticStyle: {
            "height": "56px"
          },
          on: {
            "click": function click() {
              _vm.editContact = new _vm.Contact();
              _vm.isContactDialogActive = true;
            }
          }
        }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("company.addContact")) + " ")])], 1)], 1), _vm.isUpdateAddressLoading ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _vm._e(), _c('v-card', {
          staticClass: "mb-8",
          attrs: {
            "outlined": ""
          }
        }, [_vm.value.address ? _c('v-list-item', {
          staticClass: "pa-0",
          staticStyle: {
            "min-height": "56px"
          },
          on: {
            "click": function click() {
              _vm.editAddress = _vm.value.address;
              _vm.isAddressDialogActive = true;
            }
          }
        }, [_c('v-simple-table', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.address")) + " ")]), _c('th', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "mb-n3 mt-3",
          attrs: {
            "x-small": "",
            "text": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")])], 1)])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.address.street")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.address.street
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.address.city")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.address.city
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.address.zip")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.address.zip
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.address.state")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.address.state
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("objects.address.countryCode")))]), _c('td', {
          staticClass: "text-right"
        }, [_c('copy-clipboard-text', {
          attrs: {
            "text": _vm.value.address.countryCode
          }
        })], 1)])])])], 1) : _c('v-list-item', {
          staticStyle: {
            "height": "56px"
          },
          on: {
            "click": function click() {
              _vm.editAddress = new _vm.Address();
              _vm.isAddressDialogActive = true;
            }
          }
        }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("company.addAddress")) + " ")])], 1)], 1), _c('v-select', {
          staticClass: "mt-2",
          attrs: {
            "item-value": "id",
            "items": _vm.groups,
            "label": _vm.$t('objects.company.groupId'),
            "outlined": "",
            "clearable": "",
            "loading": _vm.isLoadingGroup
          },
          on: {
            "change": _vm.saveGroup
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('company-group-create-dialog', {
                attrs: {
                  "partnerId": _vm.value.partnerId
                },
                on: {
                  "created": function created(companyGroup) {
                    return _vm.value.groupId = companyGroup.id;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on;
                    return [_c('v-btn', {
                      staticStyle: {
                        "position": "relative",
                        "top": "-6px"
                      },
                      attrs: {
                        "icon": "",
                        "loading": _vm.isLoadingCompanyGroups
                      },
                      on: {
                        "click": function click($event) {
                          $event.stopPropagation();
                          return on.apply(null, arguments);
                        }
                      }
                    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
                  }
                }])
              })];
            },
            proxy: true
          }, {
            key: "item",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(item.title) + " ")];
            }
          }, {
            key: "selection",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_vm._v(" " + _vm._s(item.title) + " ")];
            }
          }]),
          model: {
            value: _vm.value.groupId,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "groupId", $$v);
            },
            expression: "value.groupId"
          }
        }), _c('div', {
          staticClass: "mt-n7",
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "elevation": 0,
            "loading": _vm.isLoadingCompanyGroups
          },
          on: {
            "click": _vm.refreshCompanyGroups
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "elevation": 0
          },
          on: {
            "click": _vm.goToCompanyGroupCustomView
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.companyGroup && _vm.companyGroup.configuration && _vm.companyGroup.configuration.customFieldConfig && _vm.companyGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _c('v-divider'), _c('custom-field-list-form', {
          staticClass: "mt-6",
          attrs: {
            "customFieldConfig": _vm.companyGroup.configuration.customFieldConfig
          },
          on: {
            "change": _vm.debounceSaveCustomFields
          },
          model: {
            value: _vm.value.values,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "values", $$v);
            },
            expression: "value.values"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-divider', {
          staticClass: "mt-6"
        }), _c('m-action-list', {
          attrs: {
            "actions": _vm.bottomActions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-divider', {
    staticClass: "mt-2"
  }), _c('activity-card', {
    attrs: {
      "partner": _vm.partner,
      "source": _vm.source,
      "config": {
        comment: true,
        mail: false
      }
    }
  })], 1), _vm.editCompanyValues ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('company.editCompany'),
      "isDialogActive": _vm.isCompanyValuesDialogActive,
      "loading": _vm.isUpdateCompanyValuesLoading,
      "rightLoading": _vm.isUpdateCompanyValuesLoading,
      "rightDisabled": !_vm.isCompanyValuesFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCompanyValuesDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCompanyValuesDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isCompanyValuesDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isCompanyValuesDialogActive = false;
      },
      "rightClick": _vm.debounceSaveCompanyValues
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isCompanyValuesFormValid,
      callback: function callback($$v) {
        _vm.isCompanyValuesFormValid = $$v;
      },
      expression: "isCompanyValuesFormValid"
    }
  }, [_c('v-text-field', {
    ref: "companyNameField",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.company.companyName') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.editCompanyValues.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "companyName", $$v);
      },
      expression: "editCompanyValues.companyName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.editCompanyValues.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "firstName", $$v);
      },
      expression: "editCompanyValues.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.editCompanyValues.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "lastName", $$v);
      },
      expression: "editCompanyValues.lastName"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": Object.values(_vm.CompanyTypeEnum),
      "label": _vm.$t('objects.company.type'),
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyTypeEnum.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyTypeEnum.".concat(item))) + " ")];
      }
    }], null, false, 1020041308),
    model: {
      value: _vm.editCompanyValues.type,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "type", $$v);
      },
      expression: "editCompanyValues.type"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.availableLanguages,
      "label": _vm.$t('objects.company.language'),
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " ")];
      }
    }], null, false, 895571804),
    model: {
      value: _vm.editCompanyValues.language,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "language", $$v);
      },
      expression: "editCompanyValues.language"
    }
  }), _c('v-switch', {
    staticClass: "mt-n2",
    attrs: {
      "label": _vm.$t('objects.company.isTaxDeductible')
    },
    model: {
      value: _vm.editCompanyValues.isTaxDeductible,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "isTaxDeductible", $$v);
      },
      expression: "editCompanyValues.isTaxDeductible"
    }
  }), _vm.editCompanyValues.isTaxDeductible ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.taxnumber'),
      "outlined": ""
    },
    model: {
      value: _vm.editCompanyValues.taxnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.editCompanyValues, "taxnumber", $$v);
      },
      expression: "editCompanyValues.taxnumber"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.editContact ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('company.editCompany'),
      "isDialogActive": _vm.isContactDialogActive,
      "loading": _vm.isUpdateContactLoading,
      "rightLoading": _vm.isUpdateContactLoading,
      "rightDisabled": !_vm.isContactFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isContactDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isContactDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isContactDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isContactDialogActive = false;
      },
      "rightClick": _vm.debounceSaveContact
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "elevation": 0
          },
          on: {
            "click": function click() {
              _vm.editContact = null;

              _vm.debounceSaveContact();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("company.removeContact")) + " ")])];
      },
      proxy: true
    }], null, false, 1071467527)
  }, [_c('v-form', {
    model: {
      value: _vm.isContactFormValid,
      callback: function callback($$v) {
        _vm.isContactFormValid = $$v;
      },
      expression: "isContactFormValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.emailRule,
      "label": _vm.$t('objects.company.contact.email') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.editContact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editContact, "email", $$v);
      },
      expression: "editContact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.phoneRule,
      "label": _vm.$t('objects.company.contact.phone'),
      "outlined": ""
    },
    model: {
      value: _vm.editContact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editContact, "phone", $$v);
      },
      expression: "editContact.phone"
    }
  })], 1)], 1) : _vm._e(), _vm.editAddress ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('company.editAddress'),
      "isDialogActive": _vm.isAddressDialogActive,
      "loading": _vm.isUpdateAddressLoading,
      "rightLoading": _vm.isUpdateAddressLoading,
      "rightDisabled": !_vm.isAddressFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isAddressDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isAddressDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isAddressDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isAddressDialogActive = false;
      },
      "rightClick": _vm.debounceSaveAddress
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "elevation": 0
          },
          on: {
            "click": function click() {
              _vm.editAddress = null;

              _vm.debounceSaveAddress();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("company.removeAddress")) + " ")])];
      },
      proxy: true
    }], null, false, 1270922801)
  }, [_c('v-form', {
    model: {
      value: _vm.isAddressFormValid,
      callback: function callback($$v) {
        _vm.isAddressFormValid = $$v;
      },
      expression: "isAddressFormValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.city') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.editAddress.city,
      callback: function callback($$v) {
        _vm.$set(_vm.editAddress, "city", $$v);
      },
      expression: "editAddress.city"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.zip') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.editAddress.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.editAddress, "zip", $$v);
      },
      expression: "editAddress.zip"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.street') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.editAddress.street,
      callback: function callback($$v) {
        _vm.$set(_vm.editAddress, "street", $$v);
      },
      expression: "editAddress.street"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.state') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.editAddress.state,
      callback: function callback($$v) {
        _vm.$set(_vm.editAddress, "state", $$v);
      },
      expression: "editAddress.state"
    }
  }), _c('v-autocomplete', {
    staticClass: "mb-4",
    attrs: {
      "items": Object.values(_vm.CountryCodeEnum),
      "label": _vm.$t('objects.address.countryCode'),
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }], null, false, 1731429820),
    model: {
      value: _vm.editAddress.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.editAddress, "countryCode", $$v);
      },
      expression: "editAddress.countryCode"
    }
  })], 1)], 1) : _vm._e(), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-company', {
    attrs: {
      "item": _vm.value
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }