/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * A type for a company
 */
export enum CompanyTypeEnum {
  OTHER = "OTHER",
  WORKSHOP = "WORKSHOP",
  LAWYER = "LAWYER",
  APPRAISER = "APPRAISER",
  RENTAL_CAR_OPERATOR = "RENTAL_CAR_OPERATOR",
  INSURANCE = "INSURANCE",
  TOWING = "TOWING",
  FLEET = "FLEET",
  CUSTOMER = "CUSTOMER"
}
